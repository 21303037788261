.App {
  text-align: center;
}

.App-logo {
  height: 50vmin;
  pointer-events: none;
}

.link-button {
  background: none;
  border: none;
  color: #0091ff;
  text-decoration: underline;
  cursor: pointer;
  font-size: 1rem;
  font-family: inherit;
  padding: 0;
}

.separator {
  margin-left: 4px;
  margin-right: 4px;
}

.centered-container {
  display: flex;
  justify-content: right;
  align-items: right;
  font-size: calc(1.3vmin);
  height: 100%; /* Adjust this value if you want a specific height for the container */
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  display: none;
  margin-top: 16px;
  text-align: center;
}

.loading-spinner svg {
  width: 30px;
  height: 30px;
}

.loading-spinner circle {
  stroke-dasharray: 113;
  stroke-dashoffset: 113;
  transform-origin: center;
  animation: spin 1s linear infinite, draw 1s linear infinite;
}

.chat-loading-spinner {
  display: none;
  margin-top: 16px;
  text-align: center;
}

.chat-loading-spinner svg {
  width: 30px;
  height: 30px;
}

.chat-loading-spinner circle {
  stroke-dasharray: 113;
  stroke-dashoffset: 113;
  transform-origin: center;
  animation: spin 1s linear infinite, draw 1s linear infinite;
}

.spin-fresh {
  animation: spin 1s linear;
}

@keyframes spin-fresh {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes draw {
  0% { stroke-dashoffset: 113; }
  100% { stroke-dashoffset: 0; }
}
.ace_dark .ace_scrollbar {
  background-color: #444;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  padding-left: 4px;
}

.cool-button {
  background: linear-gradient(to right, #36D1DC, #5B86E5); /* Gradient effect */
  border: none;
  border-radius: 5px;
  color: white;
  padding: 12px 25px;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease-in-out;
  font-size: 16px; /* Larger font size for emphasis */
  letter-spacing: 0.5px; /* Space between letters for aesthetics */
  position: relative; /* To add an icon before the text */
}

.cool-button:hover {
  transform: translateY(-2px);
  box-shadow: 0px 7px 10px rgba(50, 50, 93, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.cool-button:active {
  transform: translateY(1px);
}

.cool-button:before {
  content: '💾'; /* Database (or save) related icon */
  margin-right: 8px; /* Spacing between the icon and text */
}

code {
  font-family: 'Courier New', Courier, monospace;
  background-color: #f0f0f0;
  padding: 2px 4px;
  border-radius: 4px;
}

/* StyledModal CSS */
.modall {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  max-width: 50%;
  max-height: 80vh;
  overflow-y: auto;
  border-radius: 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
}

.modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.modal-header-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.modal-body {
  overflow-y: auto;
  flex-grow: 1;
}
.modall-dark {
  background-color: rgba(0, 0, 0, 0.7); /* Darker overlay for dark mode */
}

.modal-content-dark {
  background-color: #282c34; /* Dark background for the content */
  color: #fff; /* Light text color */
}


pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

button {
  align-self: flex-start;
  background-color: #007BFF;
  color: white;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

button:hover {
  background-color: #0056b3;
}

/* StyledTextarea CSS */
.textarea {
  box-sizing: border-box;
  width: 100%;
  height: 500px;
  margin-bottom: 10px;
  font-size: 14px;
}

@media (max-width: 768px) {
  .textarea {
    font-size: 16px;
    padding: 0;
    border: none;
  }
}

/* Light Mode Button */
.btn-light {
  padding: 3px 10px;
  font-size: 1rem;
  color: #444; /* Dark text for light background */
  background-color: #fff; /* Light background */
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  transition: border-color 0.3s, color 0.3s;
  text-align: center;
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 2px;
  text-decoration: none;
}

/* Light Mode Button Hover */
.btn-light:hover {
  background-color: #e0e0e0; /* Lighter grey background on hover */
  color: #333; /* Slightly darker text color on hover */
  border-color: #bbb; /* Lighter border color on hover */
}

/* Dark Mode Button */
.btn-dark {
  padding: 3px 10px;
  font-size: 1rem;
  color: #fff; /* Light text for dark background */
  background-color: #424857; /* Dark background */
  border: 1px solid #626C80;
  border-radius: 5px;
  cursor: pointer;
  transition: border-color 0.3s, color 0.3s;
  text-align: center;
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 2px;
  text-decoration: none;
}

/* Dark Mode Button Hover */
.btn-dark:hover {
  background-color: #50576b; /* Slightly lighter dark background on hover */
  color: #ffffff; /* Brighter text color on hover */
  border-color: #777; /* Lighter border color on hover */
}

.button {
  padding: 3px 10px;
  font-size: 16px;
  color: #fff;
  font-weight: lighter;
  color: #fff; 
  background-color: #0080FF; 
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  margin-top: 10px;
  margin-bottom: 10px;
}

.button:hover {
  background-color: #006eff; 
}

.btn {
  background: none;
  border: none;
  padding: 3px 10px; /* Maintains the clickable area */
  font-size: 1rem;
  color: #444; /* Change color as needed */
  cursor: pointer;
  text-align: center;
  margin-left: 5px;
  margin-bottom: 2px;
  text-decoration: none; /* No underline */
  display: inline-block;
}

.sleek-input-light {
  font-size: 1rem;
  padding-top: 3px;
  padding-bottom: 3px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box; /* Include padding and border in the element's width */
  margin-left: 5px; /* Add space below the input */
  padding-left: 8px;
  /* Add a subtle shadow to give depth */

  /* Transition for the effects to apply smoothly */
  transition: border-color 0.3s, box-shadow 0.3s;
}
.sleek-input-dark {
  font-size: 1rem;
  padding-top: 3px;
  padding-bottom: 3px;
  border: 1px solid #626C80;
  color: #fff; /* Lighter text color for dark mode */
  background: #313640; /* Dark background for the input */
  border-radius: 5px;
  box-sizing: border-box; /* Include padding and border in the element's width */
  margin-left: 5px; /* Add space below the input */
  padding-left: 8px;
  /* Add a subtle shadow to give depth */
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);

  /* Transition for the effects to apply smoothly */
  transition: border-color 0.3s, box-shadow 0.3s;
}

.sleek-input-dark:focus {
  border-color: #007bff; /* Highlight color when input is focused */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Glow effect on focus */

  /* Removes the default focus outline to replace with the box-shadow */
  outline: none; 
}

/* Placeholder styling */
.sleek-input-light::placeholder {
  color: #aaa; /* Placeholder text color */
}

.sleek-input-light:focus {
  border-color: #007bff; /* Highlight color when input is focused */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Glow effect on focus */

  /* Removes the default focus outline to replace with the box-shadow */
  outline: none; 
}

/* Placeholder styling */
.sleek-input-dark::placeholder {
  color: #aaa; /* Placeholder text color */
}

.sleek-select-light {
  font-size: 1rem;
  padding: 3px 8px; /* Top and bottom padding + Left and right padding */
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box; /* Include padding and border in the element's width */
  background: #fff; /* White background for light mode */
  color: #000; /* Black text color for light mode */

  /* Transition for the effects to apply smoothly */
  transition: border-color 0.3s, box-shadow 0.3s;
}

.sleek-select-light:focus {
  border-color: #007bff; /* Highlight color when select is focused */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Glow effect on focus */
  outline: none; /* Removes the default focus outline to replace with the box-shadow */
}

.sleek-select-dark {
  font-size: 1rem;
  padding: 3px 8px; /* Top and bottom padding + Left and right padding */
  border: 1px solid #626C80;
  border-radius: 5px;
  box-sizing: border-box; /* Include padding and border in the element's width */
  background: #313640; /* Dark background for the select */
  color: #fff; /* Lighter text color for dark mode */

  /* Transition for the effects to apply smoothly */
  transition: border-color 0.3s, box-shadow 0.3s;
}

.sleek-select-dark:focus {
  border-color: #007bff; /* Highlight color when select is focused */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Glow effect on focus */
  outline: none; /* Removes the default focus outline to replace with the box-shadow */
}


.sleek-checkbox-light {
  accent-color: #007bff; /* Primary color for the checkbox */
}

.sleek-checkbox-dark {
  accent-color: #007bff; /* Primary color for the checkbox */
  background: #313640; /* Dark background for dark mode */
  border-color: #626C80; /* Border color for dark mode */
}

.sleek-checkbox-dark:focus {
  border-color: #007bff; /* Highlight color when input is focused */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Glow effect on focus */

  /* Removes the default focus outline to replace with the box-shadow */
  outline: none; 
}
.sleek-checkbox-light:focus {
  border-color: #007bff; /* Highlight color when input is focused */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Glow effect on focus */

  /* Removes the default focus outline to replace with the box-shadow */
  outline: none; 
}

.sleek-input-api-light {
  font-size: 1rem;
  padding-top: 3px;
  padding-bottom: 3px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box; /* Include padding and border in the element's width */
  margin-left: 0px; /* Add space below the input */
  padding-left: 8px;
  /* Add a subtle shadow to give depth */

  /* Transition for the effects to apply smoothly */
  transition: border-color 0.3s, box-shadow 0.3s;
}
.sleek-input-api-dark {
  font-size: 1rem;
  padding-top: 3px;
  padding-bottom: 3px;
  border: 1px solid #626C80;
  color: #fff; /* Lighter text color for dark mode */
  background: #313640; /* Dark background for the input */
  border-radius: 5px;
  box-sizing: border-box; /* Include padding and border in the element's width */
  margin-left: 0px; /* Add space below the input */
  padding-left: 8px;
  /* Add a subtle shadow to give depth */
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);

  /* Transition for the effects to apply smoothly */
  transition: border-color 0.3s, box-shadow 0.3s;
}

.sleek-input-api-dark:focus {
  border-color: #007bff; /* Highlight color when input is focused */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Glow effect on focus */

  /* Removes the default focus outline to replace with the box-shadow */
  outline: none; 
}

/* Placeholder styling */
.sleek-input-api-light::placeholder {
  color: #aaa; /* Placeholder text color */
}

.sleek-input-api-light:focus {
  border-color: #007bff; /* Highlight color when input is focused */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Glow effect on focus */

  /* Removes the default focus outline to replace with the box-shadow */
  outline: none; 
}

/* Placeholder styling */
.sleek-input-api-dark::placeholder {
  color: #aaa; /* Placeholder text color */
}