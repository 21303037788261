  .logout-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    background-color: transparent;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.2s ease;
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 1000;
  }
  
  /* Dark Mode Styles */
  .logout-button.dark-mode {
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .logout-button.dark-mode:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.3);
  }
  
  /* Light Mode Styles */
  .logout-button.light-mode {
    color: #333;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  
  .logout-button.light-mode:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border-color: rgba(0, 0, 0, 0.3);
  }
  
  .logout-icon {
    margin-right: 6px;
    font-style: normal;
  }
  
  /* For mobile view, show only the icon */
  @media (max-width: 768px) {
    .logout-button span {
      display: none;
    }
    
    .logout-button {
      padding: 8px;
    }
    
    .logout-icon {
      margin-right: 0;
    }
  }