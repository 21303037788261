/* LoginPage.css */
.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    padding: 20px;
  }
  
  .login-container {
    width: 100%;
    max-width: 420px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    padding: 40px;
    transition: all 0.3s ease;
  }
  
  .login-container:hover {
    box-shadow: 0 12px 28px rgba(0, 0, 0, 0.15);
  }
  
  .login-header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .login-logo {
    height: 60px;
    margin-bottom: 15px;
  }
  
  .login-header h1 {
    font-size: 28px;
    font-weight: 700;
    color: #333;
    margin-bottom: 8px;
  }
  
  .login-subtitle {
    color: #666;
    font-size: 16px;
    font-weight: 400;
  }
  
  .login-error {
    background-color: #fff0f0;
    color: #e53e3e;
    padding: 12px 15px;
    border-radius: 6px;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    font-size: 14px;
    border-left: 4px solid #e53e3e;
  }
  
  .error-icon {
    margin-right: 10px;
    font-size: 16px;
  }
  
  /* Success message styling (for password reset confirmation) */
  .login-success {
    background-color: #f0fff4;
    color: #38a169;
    padding: 12px 15px;
    border-radius: 6px;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    font-size: 14px;
    border-left: 4px solid #38a169;
  }
  
  .success-icon {
    margin-right: 10px;
    font-size: 16px;
  }
  
  .login-form .form-group {
    margin-bottom: 20px;
  }
  
  .login-form label {
    display: block;
    margin-bottom: 6px;
    font-weight: 500;
    color: #4a5568;
    font-size: 14px;
    text-align: left;
  }
  
  .input-container {
    position: relative;
  }
  
  .input-icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #a0aec0;
    font-size: 16px;
  }
  
  .login-form input {
    width: 100%;
    padding: 12px 12px 12px 12px;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 16px;
    transition: all 0.2s;
    background-color: #f8fafc;
    text-align: center;
  }
  
  .login-form input:focus {
    outline: none;
    border-color: #4299e1;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.15);
    background-color: #fff;
  }
  
  .login-form input::placeholder {
    color: #a0aec0;
  }
  
  /* Forgot Password Link Styles */
  .forgot-password-container {
    text-align: left;
    margin-top: 6px;
    margin-bottom: 15px;
  }
  
  .forgot-password-link {
    font-size: 14px;
    color: #3182ce;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.2s;
  }
  
  .forgot-password-link:hover {
    text-decoration: underline;
    color: #2b6cb0;
  }
  
  /* Form links for Back to Login button */
  .form-links {
    text-align: center;
    margin-top: 20px;
  }
  
  .text-button {
    background: none;
    border: none;
    color: #3182ce;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    padding: 0;
    transition: color 0.2s;
  }
  
  .text-button:hover {
    text-decoration: underline;
    color: #2b6cb0;
  }
  
  .login-button {
    width: 100%;
    padding: 12px;
    background-color: #3182ce;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s;
    margin-top: 10px;
  }
  
  .login-button:hover:not(:disabled) {
    background-color: #2b6cb0;
  }
  
  .login-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .login-button.loading {
    background-color: #4299e1;
  }
  
  .loader-text {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .loader {
    width: 16px;
    height: 16px;
    border: 2px solid #ffffff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
    animation: rotation 1s linear infinite;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .login-footer {
    text-align: center;
    margin-top: 30px;
    color: #718096;
    font-size: 14px;
  }
  
  .login-footer a {
    color: #3182ce;
    text-decoration: none;
    font-weight: 500;
  }
  
  .login-footer a:hover {
    text-decoration: underline;
  }
  
  /* Responsive adjustments */
  @media (max-width: 480px) {
    .login-container {
      padding: 30px 20px;
    }
    
    .login-header h1 {
      font-size: 24px;
    }
  }