.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the app fills the viewport height */
}

.Content {
  flex: 1;
}

.form-container {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  margin-top: 15px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.sidebar-light::after {
  content: "";
  position: absolute;
  right: 0;
  top: 10%; /* Adjust these values as needed */
  bottom: 2%;
  width: 2px;
  background-color: #e0e0e0; /* Light grey border color */
}

.sidebar-dark::after {
  content: "";
  position: absolute;
  right: 0;
  top: 10%; /* Adjust these values as needed */
  bottom: 2%;
  width: 2px;
  background-color: #606060; /* Light grey border color */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Light/Dark mode toggle button styling */
.toggle-button {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  background-color: #f0f0f0; /* Light background for the light mode */
  color: #333; /* Darker text for contrast */
  border-radius: 30px; /* Rounded edges for a pill shape */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Slight shadow for depth */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-bottom: 10px;
  gap: 8px; /* Spacing between icon and text */
}

.toggle-button:hover {
  background-color: #e0e0e0; /* Slightly darker on hover for light mode */
  transform: scale(1.05); /* Slight scale up for interaction feedback */
}

.toggle-button.dark-mode {
  background-color: #292c35; /* Darker background for dark mode */
  color: #f0f0f0; /* Lighter text for visibility in dark mode */
}

.toggle-button.dark-mode:hover {
  background-color: #3b3f4a; /* Lighter background on hover for dark mode */
}

.sidebar-container {
  position: relative;
}

.hide-sidebar .sidebar {
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}

.sidebar-tab-dark {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  background-color: #333; 
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  z-index: 1000;
  margin-top: calc(7%);
  transition: left 0.3s;
}

.sidebar-tab-light {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  background-color: #f0f0f0; 
  color: 444;
  padding: 5px 10px;
  cursor: pointer;
  z-index: 1000;
  margin-top: calc(7%);
  transition: left 0.3s;
}

.sidebar-tab-dark:hover {
  background-color: #444;
}

.sidebar-tab-light:hover {
  background-color: #e0e0e0;
}

ul {
  padding: 0;
  margin: 0;
}

ul li {
  text-decoration: none;
  color: #007bff; /* Example blue color */
  transition: color 0.3s ease;
}

ul li a:hover {
  text-decoration: underline;
}
 
ul li {
  text-decoration: none;
  color: #007bff;
  cursor: pointer; /* Changes cursor to hand symbol */
}

ul li {

  color: white;
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 5px;
  display: inline-block;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

/* Light mode styles */
.light-mode ul li {
  color: #333; /* Example blue color */
}

.light-mode ul li:hover {
  text-decoration: underline;
}

/* Dark mode styles */
.dark-mode ul li {
  color: white;
}

.dark-mode ul li:hover {
  text-decoration: underline;
}

.no-style-link {
  text-decoration: none;
  color: inherit; /* This will make the link use the color of the parent element */
  cursor: pointer; /* Optional, if you want to keep the pointer cursor */
}

.sidebar-footer {
  margin-top: auto; /* This will push the footer to the bottom */
  /* ... other styles ... */
}

.sidebar-nav {
  margin-top: 50px;
}

.button {
  padding: 3px 10px;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  color: #fafafa; 
  background-color: #0080FF; 
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  margin-top: 10px;
  margin-bottom: 10px;
}

.button:hover {
  background-color: #006eff; 
}
